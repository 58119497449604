<template>
  <div class="container">
    <div class="row d-flex justify-content-around align-items-center">
      <div class="col-3 text-left">
        <img class="w-75" src="/assets/images/logo.svg" />
      </div>
      <div class="col-6">
        <h3 class="header-title mb-0 mt-3 text-center text-uppercase">Comprobante Gasto Expediente</h3>
      </div>
      <div class="col-3 text-right">
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-5">
        <table class="table border">
          <tbody>
            <tr>
              <th class="font-weight-bold"><h5>Fecha de Pago</h5></th>
              <th>{{ expense.created_at | moment('DD-MM-YYYY') }}</th>
              <th rowspan="3" class="text-center border">
                <p>Monto pagado</p>
                <h1>{{ expense.amount | formatMoney }}</h1>
              </th>
            </tr>
            <tr class="border">
              <td class="font-weight-bold"><h5>Descripción</h5></td>
              <td>{{ expense.description | uppercase }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table border mt-5">
          <thead class="bg-secondary text-black">
            <tr>
              <th>Cliente</th>
              <th>Nombre de Expediente</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span v-if="expense.record.client" class="col-12 mt-2">
                  <span v-if="expense.record.client.type_id === 2">
                    {{ expense.record.client.commercial_name | uppercase }}
                  </span>
                  <h4 v-else>
                    {{ expense.record.client.first_name | uppercase }} {{ expense.record.client.last_name | uppercase }}
                  </h4>
                </span>
              </td>
              <td>{{ expense.record.name }}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center align-items-center flex-column">
      <div class="mt-5 col-3 text-center">
        <button onclick="window.print()" target="_blank" href="#" class="d-print-none btn btn-info d-block">Imprimir</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ExpenseReceipt',
  props: ['id'],
  data () {
    return {
      expense: {}
    }
  },
  methods: {
    ...mapActions({
      fetchExpense: 'Expense/fetchExpense'
    })
  },
  mounted () {
    this.fetchExpense({
      id: this.id
    })
      .then(response => {
        this.expense = response.data
      })
  }
}
</script>
